nav {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #091f4f42;
    border-radius: 18px;
    box-shadow: 2px 6px 12px rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(4.3px);
    -webkit-backdrop-filter: blur(4.3px);
  }
  nav:hover {
    & .nav-texts {
      display: block;
    }
  }
  .nav-links {
    list-style: none;
  }
  .nav-link {
    margin: 1rem
  }
  .nav-link a {
    display: flex;
    cursor: pointer;
    text-decoration: none;
    color: var(--white);
  }
  .nav-link:hover {
    color: var(--blue);
    & .nav-icons, .nav-texts {
      color: var(--blue);
    }
  }
  .nav-icons {
    width: 24px;
    height: 24px;
  }
  .nav-texts {
    font-size: 15px;
    margin: 3px 0 0 0.5rem;
    display: none
  }
  .burger:hover {
    transition: 0.2s ;
     #line-one, #line-two, #line-three{
      background-color: var(--blue);
    }
  }