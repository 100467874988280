footer {
    margin-top: 2rem;
    background: #3586ff;
    padding: 100px 50px 20px 50px;;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  footer .social, footer .menu-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 0;
    flex-wrap: wrap;
  }
  footer li {
    list-style: none;
    transition: 0.6s
  }
  footer li a {
    font-size: 2em;
    color: white;
    margin: 0 10px;
    display: inline-block;
    transition: 0.5s
  }
  footer li:hover {
    transform: translateY(-6px)
  }
  footer .menu-footer li a {
    font-size: 1.2em;
    color: white;
    margin: 0 10px;
    display: inline-block;
    transition: 0.5s;
    text-decoration: none;
    opacity: 0.75
  }
  footer .menu-footer li a:hover {
    opacity: 1;
    color: var(--d-blue)
  }
  footer p {
    color: white;
    text-align: center;
    margin: 15px auto 10px auto;
    font-size: 1.1rem;
    z-index: 6
  }
  .waves {
    position: absolute;
    left: 0;
  }
  .wave {
    position: absolute;
    min-width: 100dvw;
    height: 150px;
    background: url('wave.png');
    background-size: 1000px 100px;
    top: -270px
  }
  #wave1 {
    z-index: 10;
    opacity: 1;
    bottom: 140px;
    animation: animatewave 4s linear infinite alternate;
  }
  #wave2 {
    z-index: 9;
    opacity: 0.5;
    bottom: 155px;
    animation: animatewave2 4s linear infinite alternate;
  }
  #wave3 {
    z-index: 1000;
    opacity: 0.2;
    bottom: 165px;
    animation: animatewave 3s linear infinite alternate;
  }
  #wave4 {
    z-index: 9;
    opacity: 0.7;
    bottom: 175px;
    animation: animatewave2 3s linear infinite alternate;
  }
  @keyframes animatewave {
    0% {
      background-position-x: 1000px
    }
    100% {
      background-position-x: 0px
    }
  }
  @keyframes animatewave2 {
    0% {
      background-position-x: 0px
    }
    100% {
      background-position-x: 1000px
    }
  }